@import 'utils/mixins.scss';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 36.5em;
  width: 27.5em;
  padding: 2em 2.5em 1.5em 2.5em;

  background-color: var(--bg-clr-one);

  border-radius: 0.5rem;
  box-shadow: 0px 0px 8px 8px #0000000d;

  @include for-phone-only {
    margin-right: 10%;
    height: auto;
    width: auto;
  }

  .card__back {
    position: absolute;

    top: 1.7em;
    left: 2.5em;

    font-size: 1.1em;
    color: var(--font-clr-one);

    cursor: pointer;
  }

  .card__progress {
    display: flex;
    gap: 0.5em;

    margin-bottom: 1em;

    .card__eclipse {
      width: 0.75em;
      height: 0.75em;

      background-color: var(--bg-clr-three);

      border-radius: 50%;
    }

    .card__eclipse--active {
      background-color: var(--primary-clr);
    }
  }

  .card__description {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 1.5em;

    .card__title {
      font-size: 1.75em;
      font-weight: 700;
      text-align: center;
    }

    .card__subtitle {
      margin-top: 0.5em;

      color: var(--font-clr-two);
      font-weight: 400;
    }
  }
}
