@import 'utils/mixins.scss';

.metrics-section {
  display: flex;
  justify-content: center;
  gap: 4em;

  margin-bottom: 5em;
  padding: 4em;

  background-color: var(--bg-clr-two);

  @include for-desktop-down {
    flex-wrap: wrap;
  }
}
