@import 'utils/mixins.scss';

.rewards-grid-layout {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
  grid-gap: 2em;

  margin-bottom: 3em;

  list-style-type: none;

  @include for-phone-only {
    grid-template-columns: unset;
  }
}
