.invite-date {
  display: flex;
  flex-direction: column;
  gap: 1.5em;

  height: 100%;

  .invite-date__title {
    text-align: center;
    color: var(--font-clr-one);
    font-weight: 700;
  }

  .invite-date__error {
    width: 100%;
    margin-bottom: 1em;

    text-align: center;
    font-size: 0.875em;
    color: var(--error-clr);
  }

  .invite-date__inputs {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
  }

  .invite-date__action {
    margin-top: auto;

    .invite-date__action-btn {
      width: 100%;
      text-align: center;
    }
  }
}
