$border-radius: 0.25em;

.article-type-tag {
  width: fit-content;
  padding: 0.75em;

  color: white;
  background-color: var(--primary-clr);

  &--top {
    border-radius: 0 0 $border-radius $border-radius;
  }

  &--left {
    border-radius: 0 $border-radius $border-radius 0;
  }

  &--right {
    border-radius: $border-radius 0 0 $border-radius;
  }
}
