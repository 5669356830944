.trophy-icon {
  .trophy-icon__path {
    &--primary-clr-lightest {
      fill: var(--primary-clr-lightest);
    }

    &--primary-clr {
      fill: var(--primary-clr);
    }

    &--primary-clr-dark {
      fill: var(--primary-clr-dark);
    }
  }
}
