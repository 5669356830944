@import 'utils/mixins.scss';

.settings-tile-subtitle {
  width: 30em;
  margin-bottom: 1.5em;

  color: var(--font-clr-two);

  @include for-desktop-down {
    width: 100%;
  }
}
