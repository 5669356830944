@import 'utils/mixins.scss';

.dashboard {
  padding: 2.5em 1.5em 0 1.5em;
  margin-bottom: 3em;

  color: var(--font-clr-one);

  background-color: white;
  box-shadow: 0 0 64px 1px #0f224314;
  border-radius: 0.5em;

  @include for-tablet-portrait-down {
    padding: 2.5em 0 0;
  }

  .dashboard__header {
    display: flex;
    align-items: center;

    gap: 2.75em;
    margin-bottom: 2.5em;

    font-weight: 700;

    @include for-tablet-portrait-down {
      padding: 0 1em;
    }

    .dashboard__btn {
      margin-left: auto;
      padding: 0.375em 0.75em;

      font-size: 0.875em;
    }
  }

  .dashboard__table {
    display: grid;

    padding-bottom: 1em;

    font-size: 0.875em;

    & > * {
      grid-template-columns: 1fr 1fr 1fr 1.4fr 2.2fr 1.6fr 2fr 1fr;
    }

    .table__header {
      display: grid;

      padding: 0.5em;

      border-bottom: 1px solid var(--font-clr-two);
    }
  }

  .dashboard__title {
    font-weight: 700;
    color: var(--font-clr-one);
  }

  .dashboard__subtitle {
    margin-top: 0.5em;

    color: var(--font-clr-two);
  }
}

.dashboard--no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 2.5em;
}
