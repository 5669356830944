.line-separator {
  width: 2em;
  height: 3px;

  background-color: var(--font-clr-one);
}

.line-separator--white {
  background-color: var(--bg-clr-one);
}

.line-separator--primary-color {
  background-color: var(--primary-clr);
}
