.invite-categories {
  display: flex;
  flex-direction: column;

  height: 100%;

  .invite-categories__title {
    margin-bottom: 0.5rem;

    font-size: 1.75rem;
    color: var(--font-clr-one);

    text-align: center;
  }

  .invite-categories__description {
    margin-bottom: 1rem;

    color: var(--font-clr-two);

    text-align: center;
  }
}
