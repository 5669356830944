@import 'utils/mixins.scss';

.description-step-item {
  position: relative;

  padding-left: 1em;
  padding-bottom: 1.5em;
  width: 20vw;

  border-left: 2px dashed var(--primary-clr);

  @include for-desktop-down {
    @include for-phone-up {
      padding-right: 2em;
      width: 100%;
    }
  }

  @include for-phone-only {
    width: unset;
    padding-right: 2em;
    max-width: none;
  }

  .description-step-item__title {
    margin-bottom: 0.25em;
  }

  &:before {
    content: '\f192';
    position: absolute;

    left: -9px;
    padding: 1px 0;

    font-family: 'FontAwesome';
    color: var(--primary-clr);
    background-color: snow;

    z-index: 1;
  }
}
