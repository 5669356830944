.auth-bg {
  position: relative;

  .auth-bg__image {
    position: absolute;

    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: 0;

    z-index: -2;
  }

  .auth-bg__image-mask {
    position: absolute;

    width: 100%;
    height: 100%;

    background-color: var(--primary-clr);
    opacity: 0.5;

    z-index: -1;
  }
}
