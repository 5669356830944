@import 'utils/mixins.scss';

.purchase-history-metrics {
  display: flex;
  gap: 1.5em;

  @include for-phone-only {
    display: block;
    border: 0.5em solid var(--bg-clr-one);
    border-radius: 0.5em;
    .tile {
      border-radius: 0;
    }
  }
}
