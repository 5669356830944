.invite-finish {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5em 0;

  .invite-finish__title {
    text-align: center;
    color: var(--font-clr-one);
    font-weight: 700;
    margin-bottom: 0.25em;
  }

  .invite-finish__description {
    font-size: 1em;
    font-weight: 400;
    color: var(--font-clr-two);
    text-align: center;
    margin-bottom: 5em;
  }

  .invite-finish__img {
    margin-bottom: 7em;
  }

  .invite-finish__action {
    display: flex;
    width: 100%;
  }

  .invite-finish__btn {
    width: 100%;
    text-align: center;
  }
}
