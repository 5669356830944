.article-dates-box {
  display: flex;
  gap: 0.75em;

  margin-bottom: 0.75em;

  .article-dates-box__date {
    color: var(--font-clr-two);
  }

  .article-dates-box__divider {
    width: 1px;
    height: inherit;

    background-color: var(--bg-clr-three);
  }
}
