.reward-card-content {
  padding: 1.5em 1.5em 0;
  width: 100%;
  flex-grow: 1;
  border-top: 0.5em solid var(--primary-clr);
  text-align: left;
  color: var(--font-clr-one);

  display: flex;
  flex-direction: column;

  .reward-card-content__title {
    position: relative;
    font-size: 1.5em;
    margin-bottom: 1.75em;
    white-space: pre-line;

    &::after {
      content: '';
      position: absolute;
      bottom: -0.875em;
      left: 0;
      width: 1.875em;
      height: 2px;
      background-color: var(--font-clr-one);
    }
  }

  .reward-card-content__description {
    font-size: 1.2em;
    padding-bottom: 0.75em;
  }

  .reward-card-content__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: auto;
    padding: 1em 0;
    border-top: 1px solid var(--border-clr-one);

    .reward-card-content__cost {
      font-size: 1.2em;
      font-weight: 700;
    }

    .reward-card-content__purchase-btn {
      font-size: 1.35em;
    }

    .reward-card-content__details-btn {
      font-size: 1.2em;
      font-family: inherit;
      font-weight: 700;
      color: var(--primary-clr);

      border: none;
      background-color: transparent;
    }
  }
}
