.change-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
}

.change-password-error {
  color: var(--error-clr);
}
