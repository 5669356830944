@import 'utils/mixins.scss';

.introduction-section {
  display: flex;
  flex-direction: column;

  margin-bottom: 2em;

  .introduction-section__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;

    padding: 6.5em 0;

    background-color: var(--bg-clr-two);

    .description__title {
      padding: 0 4em;
      text-align: center;
    }

    .description__content {
      width: 40em;

      text-align: center;

      @include for-desktop-down {
        width: 60%;
      }
    }
  }

  .introduction-section__steps {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 2.25em;

    top: -3em;
    padding: 0 4em;

    @include for-desktop-down {
      display: inline-grid;
      padding: 0 2em;
    }

    & > * {
      flex: 1;

      @include for-tablet-portrait-up {
        max-width: 40em;
      }
    }
  }
}
