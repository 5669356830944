@import 'utils/mixins.scss';

.summary-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 5em;

  @include for-desktop-down {
    padding: 0 2em;
  }

  .summary-section__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;

    max-width: 40em;

    .summary-section__title {
      text-align: center;
    }

    .summary-section__content {
      text-align: center;
      font-size: 1.25em;
      padding: 0 1em;
    }

    .summary-section__footer {
      display: flex;
      gap: 1em;

      margin-top: 1em;

      @include for-phone-only {
        display: grid;
      }
    }
  }
}
