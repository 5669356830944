@import 'utils/variables.scss';
@import 'utils/mixins.scss';

.settings-page {
  display: grid;
  grid-template-columns: 15em 1fr;
  grid-template-rows: repeat(4, min-content);
  gap: 1.5em;

  min-height: calc(100vh - $navbar-height);
  padding: $padding-to-navbar 4em 1.5em 4em;

  background-color: var(--bg-clr-four);

  @include for-desktop-down {
    display: contents;
  }

  .settings-page__shipping-data,
  .settings-page__payout-data,
  .settings-page__fiscal-data,
  .settings-page__account-deletion {
    grid-column: 2;
  }
}
