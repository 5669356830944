.withdraw-data-view {
  position: relative;

  .withdraw-data-view__edit-btn {
    position: absolute;

    top: 0;
    right: 0;

    color: var(--primary-clr);

    user-select: none;
    cursor: pointer;
  }
}
