@import 'utils/mixins.scss';

$horizontal-margin: 10%;

.profile-page {
  .profile__recommendation {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 7.5em;

    border-bottom: 0.5em solid var(--primary-clr);

    .recommendation__bg {
      width: 100%;
      height: 37.5em;

      object-fit: cover;
      object-position: 0 30%;
      z-index: -1;
    }

    .recommendation__card {
      position: absolute;

      top: 4em;
      left: $horizontal-margin;

      .card__form {
        width: 100%;
        height: 100%;
        min-height: 0;
      }
    }
  }

  .profile__info {
    display: flex;
    flex-direction: column;
    gap: 3em;
    padding: 0 $horizontal-margin;

    @include for-tablet-portrait-down {
      padding: 0;
    }

    .profile__menu {
      display: flex;
      gap: 1.5em;

      @include for-tablet-portrait-down {
        // 2em on 400px to 4em on 600px
        $clampedSpacing: clamp(2rem, -2rem + 16vw, 4rem);

        padding: 0 $clampedSpacing;
      }
    }
  }

  @include for-desktop-down {
    font-size: 0.8em;
  }

  @include for-tablet-portrait-down {
    font-size: 0.75em;
  }
}
