.custom-listing {
  display: flex;
  flex-direction: column;

  .custom-listing__label {
    color: var(--font-clr-three);
    font-weight: 400;
  }

  .custom-listing__value {
    display: flex;

    gap: 0.2em;

    cursor: pointer;

    .custom-listing__icon {
      position: relative;

      top: 0.2em;
    }
  }
}
