.invite-terms {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  height: 100%;

  .invite-terms__content-wrapper {
    display: grid;
    gap: 1rem;

    min-height: 0;

    .invite-terms__title {
      font-weight: 700;
      color: var(--font-clr-one);
      text-align: center;
    }

    .invite-terms__description {
      color: var(--font-clr-one);
      white-space: pre-line;

      overflow-y: auto;
    }
  }

  .invite-terms__actions {
    display: grid;
    gap: 1.25rem;

    .invite-terms__resign-btn {
      background-color: transparent;
      border: none;

      font-size: 1.125rem;
      font-family: inherit;
      color: var(--primary-clr);

      cursor: pointer;

      &:hover {
        color: var(--primary-clr-dark);
      }
    }
  }
}
