@import 'utils/mixins.scss';

.scroll-to-top-btn {
  position: sticky;
  bottom: 2em;

  width: 3em;
  height: 3em;
  padding: 0;

  font-size: 1em;

  border-radius: 50%;

  z-index: 100;

  &--hide {
    display: none;
  }
}
