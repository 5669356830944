.status-eclipse {
  width: 0.7em;
  height: 0.7em;

  flex-shrink: 0;

  border-radius: 50%;

  &--new {
    background-color: var(--candidate-status-clr-new);
  }

  &--no-response {
    background-color: var(--candidate-status-clr-no-response);
  }

  &--confirmed {
    background-color: var(--candidate-status-clr-confirmed);
  }

  &--non-compliant {
    background-color: var(--candidate-status-clr-non-compilant);
  }

  &--invited {
    background-color: var(--candidate-status-clr-invited);
  }

  &--awaiting-meeting {
    background-color: var(--candidate-status-clr-awaiting-meeting);
  }

  &--resigned {
    background-color: var(--candidate-status-clr-resigned);
  }

  &--rejected {
    background-color: var(--candidate-status-clr-rejected);
  }

  &--dropped-out {
    background-color: var(--candidate-status-clr-dropped-out);
  }

  &--hired-sale {
    background-color: var(--candidate-status-clr-hired-sale);
  }
}
