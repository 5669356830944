@import 'utils/variables.scss';

.reward-shop-page-content {
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - $navbar-height);
  padding: 0 4em;

  .reward-shop-page-content__pagination {
    margin-top: auto;
  }
}
