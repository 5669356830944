@mixin for-extra-small-phone-only {
  @media (max-width: 400px) {
    @content;
  }
}
@mixin for-small-phone-down {
  @media (max-width: 480px) {
    @content;
  }
}
@mixin for-phone-only {
  @media (max-width: 600px) {
    @content;
  }
}
@mixin for-phone-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-portrait-down {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1024px) {
    @content;
  }
}
@mixin for-desktop-down {
  @media (max-width: 1024px) {
    @content;
  }
}
