@import 'utils/mixins.scss';

.meeting-page__card-title {
  margin-bottom: 1em;

  text-align: center;
}

.meeting-page__card-content {
  margin-bottom: 1em;

  &--light-color {
    color: var(--font-clr-two);
  }
}

.meeting-page__card-meeting-info {
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  gap: 0.5em;

  @include for-tablet-portrait-down {
    margin-bottom: 5em;
  }

  .card-meeting-info__row {
    display: flex;
    gap: 0.5em;
  }
}

.meeting-page__qr-code {
  width: 70%;
}

.meeting-page__card-footer {
  display: flex;
  flex-direction: column;

  gap: 1em;

  width: 100%;
  margin-top: auto;

  .meeting-page__card-btn {
    width: 100%;
  }
}
