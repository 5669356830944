@import 'utils/variables.scss';
@import 'utils/mixins.scss';

.article-details-page {
  position: relative;

  min-height: calc(100vh - $navbar-height);

  .article-details-page__header-img {
    width: 100%;
    height: 36em;

    object-fit: cover;

    border-bottom: 0.5em solid var(--primary-clr);
  }

  .article-details-page__content-wrapper {
    max-width: 50em;
    padding: 4em 2em;

    margin: auto;

    .content-wrapper__heading {
      display: flex;
      flex-direction: column;
      gap: 1em;

      margin-bottom: 0.75em;
    }
  }

  .article-details-page__sroll-to-top-box {
    position: absolute;
    display: flex;
    align-items: flex-end;

    top: 0;
    bottom: 4em;
    right: 2em;

    @include for-desktop-down {
      display: none;
    }
  }

  @include for-desktop-down {
    font-size: 0.9em;
  }

  @include for-tablet-portrait-down {
    font-size: 0.8em;
  }

  @include for-phone-only {
    font-size: 0.7em;
  }

  @include for-small-phone-down {
    font-size: 0.6em;
  }
}
