@import 'utils/mixins.scss';

// 2em on 768px to 4em on 1024px
$clampedSpacing: clamp(2em, -4rem + 12.5vw, 4em);

.highlited-article-card {
  position: absolute;

  bottom: $clampedSpacing;
  left: $clampedSpacing;

  width: 30em;
  padding: 0 2.5em 1.5em;

  @include for-phone-only {
    right: 2em;

    width: auto;
  }

  @include for-extra-small-phone-only {
    left: 0em;
    right: 0em;

    padding: 0 1.5em 1.5em;

    border-radius: 0;
  }

  .highlited-article-card__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.75em;

    margin-top: 1.5em;

    .content-wrapper__description {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .highlited-article-card__footer {
    margin-top: 2em;
  }
}
