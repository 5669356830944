.lead-category {
  display: flex;
  align-items: center;
  gap: 1em;

  width: 100%;
  padding: 0.5em 1em;

  color: var(--font-clr-one);

  border: 1px solid var(--bg-clr-three);
  border-radius: 2px;

  &:hover {
    border-color: var(--primary-clr-dark);
    cursor: pointer;
  }

  &.selected {
    padding: 0.5em calc(1em - 1px);
    border: 2px solid var(--primary-clr);
  }

  .lead-category__icon {
    width: 2em;
    height: 2em;
  }

  .lead-category__content{
    inline-size: 85%;
    overflow-wrap: break-word;
  }
}
