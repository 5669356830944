@import 'utils/mixins.scss';

$horizontal-padding: 2.5em;

.modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  top: 50%;
  left: 50%;

  width: 30em;

  background-color: var(--bg-clr-one);
  border-radius: 0.5em;
  box-shadow: 0 0 0.5em 0.5em #0000000d;

  transform: translate(-50%, -50%);

  @include for-phone-only {
    width: 80vw;
  }

  @include for-extra-small-phone-only {
    width: 90vw;
  }

  .modal__header {
    padding: 2em 2.5em 1.5em 2.5em;

    border-bottom: 1px solid var(--border-clr-one);

    .modal__title {
      font-size: 1.25em;
      color: var(--font-clr-one);
    }
  }

  .modal__body {
    max-height: 32em;
    padding: 2em $horizontal-padding 0.25em $horizontal-padding;

    color: var(--font-clr-one);

    overflow-y: auto;

    @include for-extra-small-phone-only {
      padding: 2em 1em 0.25em 1em;
    }

    .modal__form {
      display: flex;
      flex-direction: column;
      gap: 1.5em;
    }
  }

  .modal__body--visible-overflow {
    overflow-y: visible;
  }

  .modal__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;

    padding: 1.25em $horizontal-padding;

    @include for-extra-small-phone-only {
      justify-content: center;
    }

    .modal__btn {
      padding: 0.5em 1em;
    }
  }
}
