.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;

  .login-form__error-message {
    width: 100%;
    padding: 0.75em;

    text-align: center;
    font-size: 0.875em;
    color: var(--error-clr);

    border: 1px solid var(--error-clr);
  }

  .login-form__success-message {
    color: var(--primary-clr-dark);
  }

  .login-form__forgot-password-link {
    margin-top: -1em;
    margin-left: auto;

    color: var(--primary-clr);
  }
}
