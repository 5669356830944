.select-wrapper__select {
  height: 2.625em;

  .select__control {
    height: 100%;

    border: 1px solid var(--bg-clr-three);
    border-radius: 3px;

    &:hover {
      box-shadow: none;
      border-color: var(--bg-clr-three);
      outline: none;
    }

    .select__value-container {
      margin: 0;
      padding: 0 1em;

      .select__placeholder {
        font-size: 0.875em;
        color: var(--font-clr-two);

        &::after {
          content: '*';

          position: relative;
          left: 1px;

          color: var(--error-clr);
        }
      }

      .select__single-value {
        margin: 0;

        color: var(--font-clr-one);
      }

      .select__input-container {
        margin: 0;
        padding: 0;
      }
    }
  }

  .select__control--is-focused,
  .select__control--is-focused:hover {
    box-shadow: none;
    border-color: var(--primary-clr) !important;
    outline: none;
  }

  .select__menu .select__menu-list {
    .select__option {
      color: var(--font-clr-one);

      &:hover {
        color: var(--bg-clr-one);
        background-color: var(--primary-clr);
      }
    }

    .select__option--is-selected {
      color: var(--bg-clr-one);
      background-color: var(--primary-clr);
    }
  }
}

.select-wrapper__select--hide-indicators {
  .select__control .select__indicators {
    display: none;
  }
}

.select-wrapper__select--invalid {
  .select__control {
    border-color: var(--error-clr);

    &:hover {
      border-color: var(--error-clr);
    }

    .select__value-container .select__placeholder {
      color: var(--error-clr);
    }
  }

  .select__control--is-focused,
  .select__control--is-focused:hover {
    border-color: var(--error-clr) !important;
  }
}
