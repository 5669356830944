.btn {
  height: fit-content;
  padding: 0.625em 1.25em;

  color: var(--bg-clr-one);
  font-size: 1.125em;
  background-color: var(--primary-clr);

  border: 0;
  border-radius: 2px;

  overflow-wrap: break-word;
  hyphens: auto;

  &:hover {
    background-color: var(--primary-clr-dark);
  }

  &:disabled {
    background-color: var(--bg-clr-three);
  }
}

.btn--outline {
  background-color: white;
  color: var(--primary-clr);

  border: 1px solid var(--primary-clr);

  &:hover {
    background-color: var(--primary-clr-dark);
    color: white;

    border-color: var(--primary-clr-dark);
  }
}
