.card-footer {
  display: flex;
  justify-content: space-between;

  padding-top: 1em;

  border-top: 1px solid var(--bg-clr-three);

  .card-footer__text {
    color: var(--font-clr-two);
  }

  .card-footer__link {
    color: var(--primary-clr);
    font-weight: 700;

    user-select: none;
    cursor: pointer;
  }
}
