@import 'utils/mixins.scss';

.expansion-section {
  position: relative;

  height: 45em;
  margin-bottom: 5em;

  @include for-desktop-down {
    height: auto;
    margin-bottom: 0;
  }

  .expansion-section__bg-image {
    position: absolute;

    right: 0;
    width: 70%;
    height: 100%;

    object-fit: cover;
    border-radius: 0.5em 0 0 0.5em;
    z-index: -1;

    @include for-desktop-down {
      height: 45em;
    }
  }

  .expansion-section__content-wrapper {
    width: 100%;
    height: 100%;
    padding: 5em 4em;

    @include for-desktop-down {
      padding: 5em 1.5em;
    }

    .content-wrapper__card {
      display: flex;
      flex-direction: column;
      gap: 1em;

      width: 65%;
      height: 100%;
      padding: 5em 4em;

      @include for-desktop-down {
        width: 100%;
        padding: 2.5em;
      }
    }
  }
}
