@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
@import 'utils/variables.scss';

:root {
  --primary-clr-hs: none;
  --primary-clr-l: none;

  --primary-clr-lightest: hsl(var(--primary-clr-hs), calc(var(--primary-clr-l) + 30%));
  --primary-clr-light: hsl(var(--primary-clr-hs), calc(var(--primary-clr-l) + 10%));
  --primary-clr: hsl(var(--primary-clr-hs), var(--primary-clr-l));
  --primary-clr-dark: hsl(var(--primary-clr-hs), calc(var(--primary-clr-l) - 10%));

  --shadow-clr-primary: hsla(var(--primary-clr-hs), var(--primary-clr-l), 0.3);

  --font-clr-one: #333333;
  --font-clr-two: #666666de;
  --font-clr-three: #a5a5a5;

  --bg-clr-one: #ffffff;
  --bg-clr-two: #f4f4f4;
  --bg-clr-three: #d8d8d8;
  --bg-clr-four: #f6f6f6;

  --border-clr-one: #e9e8e8;

  --error-clr: #9b0006;

  --candidate-status-clr-new: #ff8541;
  --candidate-status-clr-no-response: #b59008;
  --candidate-status-clr-confirmed: #45a58a;
  --candidate-status-clr-non-compilant: #666666de;
  --candidate-status-clr-invited: #c3f1e5;
  --candidate-status-clr-awaiting-meeting: #1e25a1;
  --candidate-status-clr-resigned: #9b0006;
  --candidate-status-clr-rejected: #333333;
  --candidate-status-clr-dropped-out: #6c0104;
  --candidate-status-clr-hired-sale: #5bcaac;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-padding-top: $scroll-padding-top;
}

body {
  color: var(--font-clr-one);
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  all: unset;
  cursor: pointer;
}

button {
  cursor: pointer;
}

h1 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.25em;
}
