@import 'utils/variables.scss';
@import 'utils/mixins.scss';

.edu-page {
  display: flex;
  flex-direction: column;
  gap: 2.5em;

  min-height: calc(100vh - $navbar-height);

  @include for-desktop-down {
    font-size: 0.8em;
  }

  @include for-tablet-portrait-down {
    font-size: 0.75em;
  }
}
