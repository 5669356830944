.candidate-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  gap: 1em;

  width: 100%;
  height: 100%;

  .candidate-info__btn {
    margin-top: auto;
    width: 100%;
  }

  .candidate-info__link {
    color: var(--primary-clr);
    cursor: pointer;
  }
}
