// 40em on 400px to 46.5em on 1024px
$clampedHeight: clamp(40em, 35.8333rem + 16.6667vw, 46.5em);

.highlited-article {
  position: relative;

  height: $clampedHeight;

  .highlited-article__img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}
