@import 'utils/mixins.scss';

.descriptive-modal {
  position: absolute;
  display: flex;
  flex-direction: column;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 30em;
  max-height: 30em;
  padding-bottom: 2em;

  background-color: var(--bg-clr-one);
  border-radius: 0.5em;
  box-shadow: 0 0 0.5em 0.5em #0000000d;

  overflow: hidden;

  @include for-phone-only {
    width: 90vw;
    max-height: 90vh;
  }

  .descriptive-modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1.25em;
    margin-bottom: 1.5em;

    border-bottom: 1px solid var(--bg-clr-three);

    .header__xmark {
      color: var(--font-clr-three);
      font-size: 1.2em;

      cursor: pointer;
    }
  }

  .descriptive-modal__body {
    padding: 0 1.5em;

    overflow-y: auto;
  }
}
