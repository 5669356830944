.phone-field {
  display: flex;
  align-items: flex-start;
  gap: 0.5em;

  width: 100%;

  .phone-field__prefix {
    padding: 0 1em;

    line-height: 2.5em;
    font-size: 1em;
    color: var(--font-clr-one);

    flex-grow: 0;

    border: 1px solid var(--bg-clr-three);
    border-radius: 3px;

    user-select: none;
  }

  .phone-field__input {
    flex: 1;
  }
}
