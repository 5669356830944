.article-attachment-list {
  .article-attachment-list__label {
    margin-bottom: 1em;
  }

  .article-attachment-list__items {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em 2em;
  }
}
