@import 'utils/mixins.scss';

.nav-login-dropdown {
  position: relative;

  .nav-login-dropdown__header {
    display: flex;
    align-items: center;
    gap: 0.5em;

    user-select: none;
    cursor: pointer;
  }

  .nav-login-dropdown__menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1em;

    top: 4.2em;
    right: -0.5em;
    width: 15em;
    padding: 1em 0 1em 0;

    font-size: 0.875em;
    background-color: var(--bg-clr-one);

    border-radius: 0.5em;
    box-shadow: 0px 0px 8px 8px #0000000d;

    & > * {
      padding: 0 1em;
    }

    .nav-login-dropdown__divider {
      width: 100%;
      height: 1px;

      background-color: var(--border-clr-one);
    }
  }
}
