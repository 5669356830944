.metric {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  width: 15em;

  .metric__value {
    font-size: 4em;
  }

  .metric__content {
    text-align: center;
  }

  .metric__link {
    color: var(--primary-clr);
    font-weight: 700;

    cursor: pointer;
  }
}
