.reward-card-tile {
  display: flex;
  flex-direction: column;
  width: 100%;

  font-family: inherit;

  overflow: hidden;
  background-color: var(--bg-clr-one);
  border: none;
  border-radius: 0.5em;
  box-shadow: 0 0 0.5em 0.5em rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 0 0.5em 0.5em var(--shadow-clr-primary);

    .reward-card-content__title {
      color: var(--primary-clr);

      &::after {
        background-color: var(--primary-clr);
      }
    }
  }
}
