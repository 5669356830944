.forgot-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
}

.forgot-password-action {
  display: flex;

  .forgot-password-action__btn {
    width: 100%;
    text-align: center;
  }
}

.forgot-password-message {
  color: var(--error-clr);
}
