@import 'utils/variables.scss';
@import 'utils/mixins.scss';

.settings-page-menu {
  position: sticky;

  top: $scroll-padding-top;

  @include for-desktop-down {
    display: none;
  }

  .settings-page-menu__list {
    list-style-type: none;

    .settings-page-menu__item {
      & + .settings-page-menu__item {
        margin-top: 1em;
      }

      .settings-page-menu__link {
        color: var(--font-clr-one);

        &:hover {
          color: var(--primary-clr);
        }
      }
    }
  }
}
