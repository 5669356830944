.article-attachment-item {
  display: flex;
  align-items: center;
  gap: 0.5em;

  cursor: pointer;
  user-select: none;

  &:hover {
    color: var(--primary-clr);
  }
}
