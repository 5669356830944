.settings-tile-header {
  display: flex;
  justify-content: space-between;

  .settings-tile-header__title {
    margin-bottom: 1.5em;

    color: var(--font-clr-one);
  }

  .settings-tile-header__btn {
    padding: 0.375em 0.75em;

    font-size: 0.875em;
  }
}
