.settings-form {
  display: flex;
  flex-direction: column;
  gap: 1.5em;

  max-width: 23.25em;

  .settings-form__footer {
    display: flex;
    gap: 1em;

    .settings-form__btn {
      padding: 0.5em 1em;
    }
  }
}
