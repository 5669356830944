.auth-card {
  display: flex;
  flex-direction: column;
  gap: 2em;

  width: 100%;
  height: 100%;
  padding: 5em;

  color: var(--font-clr-one);
  background-color: var(--bg-clr-one);

  .auth-card__header {
    .auth-card__title {
      font-size: 1.75em;
    }

    .auth-card__switch {
      display: flex;
      gap: 0.25em;

      margin-top: 0.25em;

      .switch__text {
        color: var(--font-clr-two);
      }

      .switch__link {
        color: var(--primary-clr);
      }
    }
  }

  .auth-card__description {
    margin: 1.5em 0 0.5em;
    white-space: pre-line;
  }

  .auth-card__external-auth {
    display: flex;
    flex-direction: column;
    gap: 1.25em;

    .external-auth__title {
      font-size: 1em;
    }

    .external-auth__services {
      display: flex;
      gap: 1em;

      & > * {
        flex: 1;
      }

      .external-auth__service {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0.625em 0;

        border-radius: 2px;

        cursor: pointer;

        & > * {
          font-size: 1.2em;
        }
      }

      .external-auth__service--google {
        border: 1px solid var(--bg-clr-three);
      }

      .external-auth__service--facebook {
        background-color: #1976d2;
      }

      .external-auth__service--apple {
        background-color: var(--font-clr-one);
      }
    }
  }

  .auth-card__separator {
    display: flex;
    align-items: center;
    gap: 1em;

    .separator__line {
      flex: 1;

      height: 1px;
      background-color: var(--bg-clr-three);
    }

    .separator__text {
      color: var(--font-clr-two);
    }
  }
}
