@import 'utils/mixins.scss';

// 2em on 768px to 4em on 1024px
$clampedSpacing: clamp(2em, -4rem + 12.5vw, 4em);

.article-card-item {
  display: flex;
  gap: $clampedSpacing;

  padding: 0 $clampedSpacing $clampedSpacing;

  @include for-tablet-portrait-up {
    height: 31.5em;
  }

  @include for-tablet-portrait-down {
    flex-direction: column;
  }

  .article-card-item__img-wrapper {
    flex: 2;

    position: relative;

    .img-wrapper__img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    .img-wrapper__article-type-tag {
      position: absolute;

      bottom: 2em;

      &--right {
        right: 0;
      }
    }
  }

  .article-card-item__content-wrapper {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 1em;

    padding-top: $clampedSpacing;

    @include for-tablet-portrait-down {
      padding-top: 0;
    }

    .content-wrapper__description {
      max-height: 15em;
      overflow: auto;
    }

    .content-wrapper__footer {
      margin-top: auto;
    }
  }

  &--imgRight {
    background-color: var(--bg-clr-four);

    .article-card-item__img-wrapper {
      border-left: 0.25em solid var(--primary-clr);

      .img-wrapper__img {
        border-bottom-right-radius: 0.5em;
      }
    }

    @include for-tablet-portrait-up {
      flex-direction: row-reverse;
    }
  }

  &--imgLeft {
    .article-card-item__img-wrapper {
      border-right: 0.25em solid var(--primary-clr);

      .img-wrapper__img {
        border-bottom-left-radius: 0.5em;
      }
    }
  }
}
