.withdraw-no-data {
  .withdraw-no-data__content {
    margin-bottom: 1em;
    color: var(--font-clr-two);
  }

  .withdraw-no-data__redirect {
    color: var(--primary-clr);
  }

  .withdraw-no-data__btn {
    padding: 0.375em 0.75em;
    font-size: 0.875em;
  }
}
