$focus: var(--primary-clr);

.date-picker {
  position: relative;

  .date-picker__input-wrapper {
    position: relative;

    width: 100%;
  }

  .date-picker__input {
    width: 100%;
    padding: 0 1em;

    line-height: 2.5em;
    font-size: 1em;
    font-family: inherit;
    color: var(--font-clr-one);

    border: 1px solid var(--bg-clr-three);
    border-radius: 3px;

    &:focus {
      outline: 0;
      border-color: $focus;

      & + .date-picker__input-label {
        @extend .date-picker__input-label--transform;
        color: $focus;
      }
    }
  }

  .date-picker__input--filled + .date-picker__input-label {
    @extend .date-picker__input-label--transform;
  }

  .date-picker__input--required ~ .date-picker__input-label::after {
    content: '*';

    position: relative;
    left: 1px;

    color: var(--error-clr);
  }

  .date-picker__input-label {
    position: absolute;

    top: 0.25em;
    left: 1.3em;

    line-height: 2.5em;
    font-size: 0.875em;
    color: var(--font-clr-two);

    transition: 300ms all;
  }

  .date-picker__input-label--transform {
    top: -0.625em;
    left: 0.625em;
    padding: 0 0.375em;

    line-height: 1.25em;
    font-size: 0.75em;

    background: var(--bg-clr-one);
  }

  .react-datepicker {
    width: 100%;
    border: none;
    font-family: inherit;

    .react-datepicker__header {
      background-color: transparent;
      padding: 1.625em 1em 1em;
    }

    .react-datepicker__header--time {
      display: none;
    }

    .react-datepicker__navigation {
      top: 1.25em;

      &--next {
        right: 0.5em;
      }

      &--previous {
        transform: rotate(180deg);
        left: 0.5em;
      }

      .react-datepicker__navigation-icon--next::before,
      .react-datepicker__navigation-icon--previous::before {
        transform: none;
        border: none;
        width: 8px;
        height: 12px;
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M.32.293c.378-.36.976-.388 1.388-.083l.1.083 4.89 4.646c.579.55.616 1.419.109 2.008l-.108.114-4.891 4.646c-.411.39-1.078.39-1.489 0a.963.963 0 0 1-.088-1.32l.088-.094L4.837 6 .32 1.707A.963.963 0 0 1 .231.387L.32.293Z' fill='%235BCAAC'/%3E%3C/svg%3E");
      }
    }

    .react-datepicker__current-month {
      color: var(--font-clr-one);
      font-size: 1.5em;
      font-weight: 400;

      margin-bottom: 1.25em;
    }

    .react-datepicker__month {
      margin: 0;
      padding: 1em;

      .react-datepicker__week {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 1em;
        }
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected,
      .react-datepicker__day--selected {
        border-radius: 5em;
        background-color: transparent;
        border: 1px solid var(--primary-clr-dark);
        color: var(--font-clr-one);
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        width: 2.5em;
        height: 2.5em;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .react-datepicker__day:hover,
      .react-datepicker__month-text:hover,
      .react-datepicker__quarter-text:hover,
      .react-datepicker__year-text:hover {
        border-radius: 5em;
        background-color: var(--primary-clr-lightest);
      }
    }

    .react-datepicker__day-names {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .react-datepicker__day-name {
        color: var(--font-clr-three);
      }
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__time-container {
      width: 100%;

      .react-datepicker__time .react-datepicker__time-box {
        width: 100%;
      }

      .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item:hover {
        background-color: var(--primary-clr-lightest);
      }

      .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item--selected {
        color: var(--font-clr-one);
        background-color: transparent;
        border-top: 1px solid var(--primary-clr-dark);
        border-bottom: 1px solid var(--primary-clr-dark);
      }
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    box-shadow: 0px 10px 10px 6px #0000000d;
    width: 100%;
    top: 100% !important;
    left: 0 !important;
    transform: none !important;
    padding-top: 0;
  }
}
