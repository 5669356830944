@import 'utils/mixins.scss';

.invite-page {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 7.5em;

  @include for-tablet-portrait-down {
    margin-bottom: 0;
  }

  .invite-page__bg {
    width: 100%;
    height: 33.75em;

    object-fit: cover;
    object-position: 0 30%;
    z-index: -1;

    @include for-tablet-portrait-down {
      display: none;
    }
  }

  .invite-page__card {
    position: absolute;

    bottom: -5em;
    left: 10%;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 27.5em;
    height: 35em;
    padding: 2em 2.5em 1.5em 2.5em;

    background-color: var(--bg-clr-one);

    border-radius: 0.5rem;
    box-shadow: 0px 0px 8px 8px #0000000d;

    @include for-tablet-portrait-down {
      position: unset;

      width: auto;
      height: auto;

      border-radius: unset;
      box-shadow: unset;
    }

    .invite-page__card-back {
      position: absolute;

      top: 1.7em;
      left: 2.5em;

      font-size: 1.1em;
      color: var(--font-clr-one);

      background-color: transparent;
      border: none;

      cursor: pointer;
    }

    .invite-page__card-progress {
      display: flex;
      gap: 0.5em;

      margin-bottom: 1em;

      .invite-page__card-eclipse {
        width: 0.75em;
        height: 0.75em;

        background-color: var(--bg-clr-three);

        border-radius: 50%;
      }

      .invite-page__card-eclipse--active {
        background-color: var(--primary-clr);
      }
    }
  }
}

.invite-form {
  width: 100%;
  height: 100%;
  min-height: 0;
}
