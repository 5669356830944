@import 'utils/mixins.scss';

.reward-details-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  top: 50%;
  left: 50%;

  width: 30em;

  background-color: var(--bg-clr-one);
  box-shadow: 0 0 0.5em 0.5em #0000000d;
  border-radius: 0.5em;

  transform: translate(-50%, -50%);

  @include for-phone-only {
    width: 80vw;
    max-height: 80%;
  }

  .reward-details-modal__monetary-background {
    position: relative;
    height: 18.3em;
    width: calc(100% + 5em);
    padding: 1.9em 1.3em;
    margin: 0 -2.5em;
    background-color: #f5f5f5;
    overflow: hidden;
    border-bottom: 4px solid var(--primary-clr);

    &::before {
      content: '';
      position: absolute;
      top: -188px;
      left: -257px;
      width: 385px;
      height: 385px;
      border-radius: 50%;
      background-color: var(--primary-clr-dark);
    }

    &::after {
      content: '';
      position: absolute;
      left: 121px;
      top: -40px;
      width: 100%;
      height: auto;
      padding-top: 100%;
      border-radius: 50%;
      background-color: var(--primary-clr-light);
    }

    .reward-details-modal__monetary-background-content {
      position: relative;
      height: 100%;
      width: 100%;
      padding: 1.8em;

      display: flex;
      align-items: center;

      border-radius: 1.5em;
      border: 2px solid var(--bg-clr-one);
      background-color: rgba(255, 255, 255, 0.25);
      color: var(--bg-clr-one);
      backdrop-filter: blur(2em);

      overflow: hidden;
      z-index: 2;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: -2.7em;
        transform: translateY(-50%);
        background-color: var(--bg-clr-one);
        opacity: 0.25;
        width: 5.3em;
        height: 5.3em;
        border-radius: 50%;
      }
    }

    .reward-details-modal__monetary-background-text {
      text-align: left;
    }

    .reward-details-modal__monetary-background-title {
      font-size: 2.1em;
      font-family: 'Inter', sans-serif;
    }

    .reward-details-modal__monetary-background-description {
      color: var(--primary-clr-dark);
    }
  }

  .reward-details-modal__images {
    position: relative;

    .reward-details-modal__images-wrapper {
      height: 100%;
      overflow: hidden;

      .reward-details-modal__images-container {
        height: 100%;
        display: flex;
      }

      .reward-details-modal__images-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 100%;
      }

      .reward-details-modal__images-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .reward-details-modal__nav {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      background-color: var(--primary-clr);
      padding: 0.625em 0.8125em;
      border-radius: 4px 4px 0 0;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .reward-details-modal__nav-count {
        padding: 0 1.5em;
        display: flex;
        align-items: center;
      }

      .reward-details-modal__nav-item {
        color: var(--bg-clr-one);
        font-size: 1em;
        font-weight: 700;
      }

      .reward-details-modal__nav-item--separator {
        padding: 0 0.3em;
      }

      .reward-details-modal__nav-item--disabled,
      .reward-details-modal__nav-item--separator {
        color: var(--primary-clr-lightest);
        font-weight: 400;
      }

      .reward-details-modal__nav-button {
        background-color: transparent;
        border: none;

        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          fill: var(--bg-clr-one);
        }
      }

      .reward-details-modal__nav-button--disabled {
        svg {
          fill: var(--primary-clr-dark);
        }
      }
    }
  }

  .reward-details-modal__content {
    padding: 0 2.5em 2em;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 32.5em;

    .reward-details-modal__images {
      width: calc(100% + 5em);
      margin-left: -2.5em;
      height: 16em;
      border-bottom: 4px solid var(--primary-clr);
    }

    .reward-details-modal__title {
      position: relative;
      max-width: 12.25em;
      margin-bottom: 0.57em;
      padding: 0.57em 0;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 1.15em;
        height: 3px;
        background-color: var(--font-clr-one);
      }
    }
  }

  .reward-details-modal__additional {
    .reward-details-modal__additional-header {
      display: flex;
      gap: 1em;

      padding: 2em 0;
    }

    .reward-details-modal__additional-description {
      display: none;
    }

    .reward-details-modal__additional-description--active {
      display: block;
    }
  }

  .reward-details-modal__close {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 100%;
    padding: 1.25em;

    background-color: var(--bg-clr-one);

    box-shadow: 0px 8px 8px 0px #0000000d;
    border-radius: 0.5em 0.5em 0 0;

    .reward-details-modal__close-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .reward-details-modal__action {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 1.25em 2.5em;

    background-color: var(--bg-clr-one);

    box-shadow: 0px -8px 8px 0px #0000000d;
    border-radius: 0 0 0.5em 0.5em;

    .reward-details-modal__action-points {
      font-size: 1em;
      font-weight: 700;
      color: var(--font-clr-one);
    }
  }
}
