@import 'utils/mixins.scss';

.candidates-item {
  position: relative;

  display: grid;
  align-items: center;

  padding: 0.8em 0.5em;

  &:nth-child(odd) {
    background-color: var(--bg-clr-four);
  }

  & > span {
    margin-right: 1em;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &--mobile {
    padding: 0.8em 1em;
    grid-template-columns: 10em 1fr !important;
    gap: 0.5rem;
  }

  .candidates-item__btn {
    position: absolute;
    top: 1em;
    right: 1em;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 2.5em;
    height: 2.5em;

    font-size: 1.2em;
    font-weight: 400;
    color: white;
    background-color: var(--primary-clr);

    border: none;
    border-radius: 50%;
  }
}
