@import 'utils/mixins.scss';

.purchase-history {
  display: flex;
  flex-direction: column;
  gap: 1.5em;

  .purchase-history__header {
    display: flex;
    align-items: center;

    @include for-desktop-down {
      display: contents;
    }

    .purchase-history__link {
      margin-left: auto;

      @include for-phone-only {
        margin-right: auto;
      }
    }
  }
}
