.article-section {
  margin-bottom: 2.5em;

  .article-section__content {
    text-align: justify;

    &--normal {
      font-size: 1.25em;
    }

    &--paragraph {
      padding-left: 2em;

      font-size: 1.5em;
      font-weight: 700;

      border-left: 0.25em solid var(--primary-clr);
    }
  }

  .article-section__media {
    width: 100%;
    margin-top: 2.5em;
  }
}
