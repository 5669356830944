@import 'utils/mixins.scss';

.reward-banner {
  padding: 1.5em 5em;

  background: linear-gradient(90deg, var(--primary-clr-dark) 0%, var(--primary-clr) 100%);
  border-radius: 0.5em;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .reward-banner__title {
    font-size: 1.75em;
    color: var(--bg-clr-one);
  }

  .reward-banner__content {
    @include for-desktop-down {
      display: none;
    }
  }

  .reward-banner__list {
    list-style-type: none;

    display: flex;
    align-items: center;

    .reward-banner__list-item:not(:last-child) {
      margin-right: 3em;
    }
  }
}
