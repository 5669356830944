.identifier-field {
  display: flex;
  gap: 0.5em;

  .identifier-field__select {
    width: 4.5em;
  }

  .identifier-field__input {
    flex: 1;
  }
}
