@import 'utils/variables.scss';
@import 'utils/mixins.scss';

nav {
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;

  top: 0;
  width: 100%;
  height: $navbar-height;
  padding: 1.125em 1.5em;

  color: var(--font-clr-one);

  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 10px 0px #0000001a;

  z-index: 100;

  .nav__logo {
    display: flex;
    align-items: center;

    height: 100%;

    .logo__img {
      height: 100%;
    }
  }

  .nav__menu {
    display: flex;
    align-items: center;
    gap: 1.25em;

    .nav__links {
      display: flex;
      gap: 1.5em;

      padding-right: 1.25em;

      border-right: 1px solid var(--bg-clr-three);
    }

    .nav__points {
      display: flex;
      align-items: center;
      gap: 0.5em;

      padding-right: 1.25em;

      border-right: 1px solid var(--bg-clr-three);

      @include for-small-phone-down {
        span {
          display: none;
        }
      }

      .points__count {
        @include for-small-phone-down {
          display: unset;
        }
        font-weight: 700;
        font-size: 1.25em;
      }
    }

    .nav__btn {
      padding: 0.5em 1.5em;
      font-size: 1em;
    }
  }
}
