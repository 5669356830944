.candidate-leads {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  height: 100%;
  min-height: 0;

  .candidate-leads__btn {
    margin-top: auto;
  }
}
