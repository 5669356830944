@import 'utils/mixins.scss';

.auth-layout {
  display: flex;

  height: 100vh;

  & > * {
    flex-basis: 50%;

    @include for-phone-only {
      flex-basis: unset;
    }
  }
}
