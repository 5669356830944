.tile {
  padding: 1.5em;

  background-color: var(--bg-clr-one);
  border-radius: 0.5em;
}

.tile--shadow {
  box-shadow: 0px 0px 8px 8px #0000000d;
}

.tile--shadow-light {
  box-shadow: 0px 18px 32px 0px #d0d2da26;
}

.tile--border-top {
  border-top: 0.5em solid var(--primary-clr);
}
