@import 'utils/mixins.scss';

.card-with-bg-layout {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 7.5em;

  border-bottom: 0.5em solid var(--primary-clr);

  @include for-tablet-portrait-down {
    margin-bottom: 0;
    border-bottom: unset;
  }

  .card-with-bg-layout__bg {
    width: 100%;
    height: 37.5em;

    object-fit: cover;
    object-position: 0 30%;
    z-index: -1;

    @include for-tablet-portrait-down {
      display: none;
    }
  }

  .card-with-bg-layout__card {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;

    top: 4em;
    left: 10%;

    width: 27.5em;
    height: 36.5em;
    padding: 2em 2.5em 1.5em 2.5em;

    @include for-tablet-portrait-down {
      position: unset;
      height: auto;
      width: auto;

      border-radius: unset;
      box-shadow: unset;
    }
  }
}
