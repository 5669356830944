.reward-card-image {
  width: 100%;
  height: 18.3em;

  .reward-card-image__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .reward-card-image__background {
    position: relative;
    height: 18.3em;
    width: 100%;
    padding: 1.9em 1.3em;
    background-color: #f5f5f5;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: -188px;
      left: -257px;
      width: 385px;
      height: 385px;
      border-radius: 50%;
      background-color: var(--primary-clr-dark);
    }

    &::after {
      content: '';
      position: absolute;
      left: 121px;
      top: -40px;
      width: 100%;
      height: auto;
      padding-top: 100%;
      border-radius: 50%;
      background-color: var(--primary-clr-light);
    }

    .reward-card-image__background-content {
      position: relative;
      height: 100%;
      width: 100%;
      padding: 1.8em;

      display: flex;
      align-items: center;

      border-radius: 1.5em;
      border: 2px solid var(--bg-clr-one);
      background-color: rgba(255, 255, 255, 0.25);
      color: var(--bg-clr-one);
      backdrop-filter: blur(2em);

      overflow: hidden;
      z-index: 2;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: -2.7em;
        transform: translateY(-50%);
        background-color: var(--bg-clr-one);
        opacity: 0.25;
        width: 5.3em;
        height: 5.3em;
        border-radius: 50%;
      }

      .reward-card-image__background-text {
        text-align: left;

        .reward-card-image__background-title {
          font-size: 2.1em;
          font-family: 'Inter', sans-serif;
        }

        .reward-card-image__background-description {
          color: var(--primary-clr-dark);
        }
      }
    }
  }
}
