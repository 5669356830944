.candidate-added {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;

  .candidate-added__title {
    margin-bottom: 0.5em;

    color: var(--font-clr-one);
    font-size: 1.75em;
    font-weight: 700;
  }

  .candidate-added__desc {
    margin-bottom: 4.25em;

    color: var(--font-clr-two);
    text-align: center;
  }

  .candidate-added__buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    width: 100%;
    margin-top: auto;
  }
}
