.icon-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;

  .icon-box__icon {
    width: 5em;
    height: 5em;
  }

  .icon-box__content {
    width: 17em;
    text-align: center;
  }
}
