.invite-address {
  display: flex;
  flex-direction: column;
  height: 100%;

  .invite-address__title {
    text-align: center;
    color: var(--font-clr-one);
    font-weight: 700;
    margin-bottom: 0.85em;
  }

  .invite-address__inputs {
    display: flex;
    flex-direction: column;

    gap: 1.5em;

    margin-bottom: 2em;
  }

  .invite-address__action {
    margin-top: auto;
  }

  .invite-address__btn {
    width: 100%;
    text-align: center;
  }
}
