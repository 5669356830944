.modal-base-structure {
  position: fixed;

  inset: 0;
  z-index: 100;

  .modal-base-structure__bg {
    position: fixed;

    width: 100%;
    height: 100%;

    background-color: rgba($color: #000000, $alpha: 0.2);

    user-select: none;
  }
}
