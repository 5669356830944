@import 'utils/mixins.scss';

.sideway-modal {
  position: absolute;
  display: flex;
  flex-direction: column;

  right: 0;

  width: 30em;
  height: 100%;

  background-color: var(--bg-clr-one);
  box-shadow: 0px 0px 8px 8px #0000000d;

  @include for-phone-only {
    width: 100%;
  }

  .sideway-modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1.5em;

    border-bottom: 1px solid var(--border-clr-one);

    .sideway-modal__xmark {
      color: var(--font-clr-two);
      font-size: 1.2em;

      cursor: pointer;
    }
  }

  .sideway-modal__body {
    flex: 1;

    padding: 2.5em 1.5em 1.5em 2.5em;

    list-style-type: none;
    counter-reset: li-count;

    .sideway-modal__process-step {
      position: relative;

      padding: 0 0 1.5em 2em;

      border-left: 2px dashed var(--primary-clr);
      counter-increment: li-count;

      .process-step__title {
        margin-bottom: 0.75em;
      }

      &::before {
        content: counter(li-count);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        top: -0.5em;
        left: -1.125em;
        width: 2em;
        height: 2em;

        color: var(--primary-clr);
        background-color: var(--bg-clr-one);

        border-radius: 50%;
        border: 1px solid var(--primary-clr);
      }
    }

    .sideway-modal__product-info {
      padding-bottom: 1.5em;

      & > * {
        position: relative;
        top: -20px;
      }

      .product-info__name {
        color: var(--font-clr-two);
      }

      .product-info__price {
        font-weight: 900;
      }
    }

    & > :last-child {
      border-color: var(--bg-clr-one);
      padding-bottom: 0;
    }
  }

  .sideway-modal__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-self: flex-end;

    margin: 0 1.5em;
    padding: 1.25em 0;

    border-top: 1px solid var(--border-clr-one);

    .footer__text-wrapper {
      .footer__title {
        color: var(--font-clr-two);
      }
    }
  }
}
