.reward-card-list__item {
  display: flex;
}

.reward-card-list__item--skeleton {
  height: 34.8em;
  border-radius: 0.5em;
  box-shadow: 0 0 0.5em 0.5em rgba(0, 0, 0, 0.05);
  padding: 1.5em;
}

.reward-card-list-skeleton {
  width: 100%;

  display: flex;
  flex-direction: column;

  .reward-card-list-skeleton__header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3em;
  }

  .reward-card-list-skeleton__title {
    margin-bottom: 1.5em;
  }

  .reward-card-list-skeleton__action {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
