@import 'utils/mixins.scss';

.nav-dropdown {
  position: relative;

  .nav-dropdown__header {
    display: flex;
    align-items: center;
    gap: 0.5em;

    user-select: none;
    cursor: pointer;

    .header__label {
      max-width: 500px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include for-desktop-down {
        max-width: 19.5vw;
      }
    }
  }


  .nav-dropdown__menu {
    position: absolute;
    display: flex;
    flex-direction: column;

    top: 4.2em;
    right: -0.5em;
    width: 15em;
    padding: 1.5em 0 1em 0;

    font-size: 0.875em;
    background-color: var(--bg-clr-one);

    border-radius: 0.5em;
    box-shadow: 0px 0px 8px 8px #0000000d;

    & > * {
      padding: 0 1em;
    }

    .nav-dropdown__userinfo {
      display: flex;
      flex-direction: column;

      .userinfo__username {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }

      .userinfo__label {
        color: var(--font-clr-two);
      }
    }

    .nav-dropdown__links {
      display: flex;
      flex-direction: column;
      gap: 0.5em;

      padding-top: 0.75em;
      padding-bottom: 0.75em;
      margin: 0.75em 0;

      border-top: 1px solid var(--border-clr-one);
      border-bottom: 1px solid var(--border-clr-one);
    }

    .nav-dropdown__logout {
      color: var(--error-clr);
      cursor: pointer;
    }
  }
}
