.candidate-lead-categories {
  position: relative;
  display: flex;
  align-items: center;

  gap: 0.3em;

  .candidate-lead-categories__icon {
    width: 1.8em;
    height: 1.8em;
  }

  .candidate-lead-categories__tooltip {
    position: absolute;
    display: none;
    flex-direction: column;

    top: 2.125em;
    padding: 0.5em;

    width: 15em;

    font-size: 0.875em;
    color: var(--bg-clr-one);

    background-color: var(--font-clr-two);

    border-radius: 2px;

    z-index: 1;
  }

  &:hover,
  &:active {
    .candidate-lead-categories__tooltip {
      display: flex;
    }
  }
}
