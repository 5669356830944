@import 'utils/mixins.scss';

.description-section {
  position: relative;
  display: flex;
  align-items: center;

  height: auto;
  min-height: 45em;
  margin-bottom: 7em;

  @include for-desktop-down {
    margin-bottom: 5em;
  }

  .description-section__content-wrapper {
    display: grid;
    align-items: center;
    gap: 4em;

    width: 100%;
    height: 100%;
    padding: 5em 4em;

    @include for-phone-only {
      gap: 0;

      padding: 0 0 5em;
    }

    @include for-desktop-up {
      display: flex;
    }

    .content-wrapper__title {
      width: 60%;
      text-align: center;
    }

    .content-wrapper__description {
      display: flex;
      flex-direction: column;
      gap: 1em;

      flex: 1;

      color: var(--bg-clr-one);

      @include for-desktop-down {
        width: 80%;
      }

      @include for-phone-only {
        padding: 5em 4em;
        width: 100%;
      }

      .description__content {
        ol, ul {
          padding-left: 1em;
        }
      }
    }

    .content-wrapper__steps {
      display: flex;
      gap: 5em;

      min-width: 65%;
      height: 100%;
      padding: 0 4.5em;

      @include for-desktop-down {
        display: block;
        width: 80%;
      }


      @include for-phone-only {
        margin-right: auto;
        margin-left: auto;

        padding: 0 2em 0 4.5em;
      }

      .steps__block {
        display: flex;
        flex-direction: column;

        list-style-type: none;

        .steps__space-box {
          border-left: 2px dashed var(--primary-clr);
        }
      }

      .steps__block--from-down {
        padding-top: 5em;
        width: 50%;

        @include for-desktop-down {
          width: 100%;
        }

        .steps__space-box {
          flex: 1;
        }
      }

      .steps__block--from-up {
        width: 50%;

        @include for-desktop-down {
          width: 100%;
        }

        & > :last-child {
          padding-bottom: 0;
          border-color: var(--bg-clr-one);

          @include for-desktop-down {
            padding-bottom: 5em;
          }

        }

        .steps__space-box {
          height: 5em;

          @include for-desktop-down {
            height: 0;
          }
        }
      }
    }
  }

  .description-section__bg {
    position: absolute;

    width: 80%;
    height: 100%;

    background: linear-gradient(90deg, var(--primary-clr-dark) 0%, var(--primary-clr) 100%);

    border-radius: 0 0.5em 0.5em 0;
    z-index: -1;

    @include for-desktop-down {
      width: 90%;
    }

    @include for-phone-only {
      width: 100%;

      border-radius: 0 0 0 0;
    }
  }
}
