$active-hover: var(--primary-clr);

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 0.5em;
  padding: 1.5em;

  color: var(--font-clr-two);

  border-top: 1px solid var(--bg-clr-three);

  list-style-type: none;
  user-select: none;

  .pagination__page-item {
    font-weight: 700;

    &:hover {
      color: $active-hover;
    }
  }

  .pagination__page-item--active {
    color: $active-hover;
  }

  .pagination__label {
    position: relative;
    top: 0.15em;

    &:hover {
      color: $active-hover;
    }
  }

  .pagination__label--disabled {
    display: none;
  }
}
