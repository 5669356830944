@import 'utils/mixins.scss';

.settings-tile-record {
  display: grid;
  grid-template-columns: 13.5em 1fr auto;

  @include for-phone-only {
    grid-template-columns: 6.5em 1fr auto;
  }

  .settings-tile-record__label {
    color: var(--font-clr-two);
  }

  .settings-tile-record__value {
    display: flex;
    flex-direction: column;

    color: var(--font-clr-one);

    @include for-desktop-down {
      display: block;
      max-width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .settings-tile-record__value--bold {
    font-weight: 700;
  }

  .settings-tile-record__value--second {
    grid-row: 2;
    grid-column: 2;
  }

  .settings-tile-record__value--password {
    letter-spacing: 0.1em;
    font-size: 1.2em;
  }

  .settings-tile-record__btn {
    color: var(--primary-clr);

    user-select: none;

    cursor: pointer;
  }

  & + .settings-tile-record {
    margin-top: 1em;
  }
}
