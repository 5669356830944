.content-switcher-button {
  position: relative;

  font-size: 1.25em;
  font-weight: 700;
  font-family: inherit;
  color: var(--font-clr-one);
  background-color: transparent;

  border: none;
}

.content-switcher-button--active {
  color: var(--primary-clr);

  &::before {
    content: '';
    position: absolute;
    bottom: -0.75em;
    left: 0;
    width: 0.8em;
    height: 2px;
    background-color: var(--primary-clr);
  }
}
