.icons-section {
  display: flex;
  justify-content: center;

  margin-bottom: 5em;
  padding: 4em;

  background-color: var(--bg-clr-two);

  .icons-section__icons-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3em;

    max-width: 80em;
  }
}
