@import 'utils/mixins.scss';

.metric-tile {
  display: flex;
  align-items: center;
  gap: 1.5em;

  width: 16.875em;
  height: 6.5em;
  padding: 1.25em 1em;

  @include for-desktop-down {
    width: 100%;
  }

  .metric-tile__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 4em;
    height: 4em;

    border-radius: 50%;
    background-color: var(--bg-clr-four);

    .metric-tile__icon {
      width: 2.25em;
      height: 2.25em;
    }
  }

  .metric-tile__content {
    .metric-tile__description {
      color: var(--font-clr-three);
    }

    .metric-tile__value {
      font-size: 1.875em;
      font-weight: 800;
    }
  }
}
